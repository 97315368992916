<template>
  <div class="index-wrap d-flex flex-column-reverse flex-md-row justify-center">
    <div class="index-chart-wrap white mr-2 py-12 px-13 pb-9 d-flex flex-column">
      <h2 class="rem-24 font-weight-bold primary--text" v-text="$t('totalDepositEth')" />
      <div id="chart-total-amount"></div>
    </div>
    <div class="vault-index">
      <v-container>
        <div class="small-rem-9 font-w-4 mt-md-10 mb-4 mb-md-7 mx-md-8 subtitle-text">{{ $t('indexSubtitle') }}</div>
        <div class="rem-10 rem-sm-36 font-weight-bold primary--text mb-4 mb-md-8 mx-md-8 title-text pre-wrap" :style="{lineHeight: $store.state.nowWidth > 960 ? '60px' : '26px'}">
          <div v-text="$t('indexTitle')" />
          <div class="font-w-7 rem-1 white--text home-lock-btn pr-5 pl-3 py-1 d-flex align-center" :style="{background: $store.state.isMember ? '#33BA1D' : '#A70000'}">{{ $t($store.state.isMember ? 'unlock' : 'lock') }}</div>
        </div>
  
        <div class="deposits-wrap px-md-3">
          <div
            v-for="(item, i) in $store.state.depositTokens"
            :key="i"
            class="deposit-card d-flex flex-column align-center pt-10 pb-9 px-10 mb-3"
          >
            <img class="mr-2 mb-3" :src="`${require(`@/assets/img/icon-logo-${item.token}.svg`)}`" :alt="item.token" width="110">
            <div class="d-flex align-center mb-10">
              <div class="font-share-tech">
                <span class="rem-13 rem-sm-16 font-weight-bold lightPrimary2--text mr-1">{{ item.token.toUpperCase() }}</span>
                <span class="rem-3 rem-sm-4 font-w-4 primary--text">/{{ currInterestToken.toUpperCase() }}</span>
              </div>
            </div>
            <btnPill
              btnText="depositImmediately"
              @clickBtn="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: currInterestToken, depositToken: item.token}})"
            />
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import btnPill from '@/components/btn-pill'
import * as echarts from 'echarts';
export default {
  data(){
    return {
      currInterestToken: '',
      myChart: {}
    }
  },
  components: {
    btnPill
  },
  watch: {},
  methods: {
    async getChartData () {
      const result = await this.$store.dispatch('getHomeChartData')
      const dates = []
      const values = []
      if (result.status !== 200 || !result.data) {
        return { dates, values }
      }

      return {
        dates: result.data.map(item => item.date),
        values: result.data.map(item => parseFloat(item.amonut))
      }
    },
    async initChart () {
      const { dates, values } = await this.getChartData()

      const chartDom = document.getElementById('chart-total-amount')
      this.myChart = echarts.init(chartDom)

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          bottom: 90
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%']
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            areaStyle: {
              color: '#F0CD8F'
            },
            itemStyle: {
              color: '#F0CD8F'
            },
            data: values,
          }
        ]
      }

      this.myChart.setOption(option)
    },
    resizeChart () {
      if (this.myChart) {
        this.myChart.resize()
      }
    }
  },
  async mounted(){
    this.currInterestToken = this.$store.state.interestTokens[0].token

    this.initChart()
    window.addEventListener('resize', this.resizeChart)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  }
}
</script>
<style lang="scss">
.index-wrap {
  margin: 40px auto;
}
.index-chart-wrap {
  border-radius: 11px;
  width: 53%;
  @include dai_vuetify_md {
    width: 100%;
  }

  #chart-total-amount {
    max-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
}
.vault-index {
  @include dai_vuetify_md_min {
    max-width: 420px;
    min-width: 420px;
    width: 50%;
    background: white;
    border-radius: 11px;
  }

  .subtitle-text {
    color: var(--v-lightGrey1-base);
    @include dai_vuetify_md {
      color: white;
    }
  }

  .deposit-card {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $color-gradient;
      filter: blur(8px);
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: white;
      border-radius: 5px;
    }
  }

  .title-text {
    position: relative;
    .home-lock-btn {
      position: absolute;
      height: 24px;
      bottom: 12px;
      box-shadow: 0px 0.8px 0.8px rgba(0, 0, 0, 0.25);
      right: -44px;
      border-radius: 13px 0 0 13px;
    }
  }
}
</style>